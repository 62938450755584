@charset "UTF-8";

@import url(https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&subset=japanese);

@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700);

@import url(https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&subset=japanese);

@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700);

@media screen and (max-width: 769px) {
  .sec {
    padding: 0 20px;
    box-sizing: border-box;
  }
}

.sec {
  &.login {
    dl {
      margin-top: 40px;

      dt {
        font-size: 18px;
        font-size: 1.8rem;
        font-family: "Noto Serif JP",serif;
      }

      dd {
        margin-top: 10px;

        p {
          margin-top: 1em;
          display: block;
          text-align: right;

          a {
            color: #000;
            font-size: 14px;
            font-size: 1.4rem;
            text-decoration: underline;
          }
        }

        + dt {
          margin-top: 20px;
        }
      }
    }

    .check_label {
      text-align: center;
      margin-top: 20px;
      font-size: 16px;
      font-size: 1.6rem;
    }

    .login__btnarea .btn:before {
      background-image: url(../images/index/icon_login.svg);
      width: 32px;
      height: 38px;
      margin-top: -19px;
    }
  }

  &.regist {
    margin: 80px auto 200px;

    p {
      margin-top: 60px;
      font-size: 14px;
      font-size: 1.4rem;
      color: #707070;

      span {
        color: #bf000a;
      }

      + p {
        margin-top: 1em;
      }
    }

    .regist__btnarea .btn {
      background: #bf000a;

      &:before {
        background-image: url(../images/index/icon_shinki.svg);
        width: 34px;
        height: 36px;
        margin-top: -18px;
      }
    }
  }

  .btnarea {
    margin-top: 40px;

    .btn {
      background: #000;
      width: 100%;
      height: 63px;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      font-size: 1.4rem;
      transition: all .3s ease;
      line-height: 63px;
      position: relative;
      border: none;

      &:before {
        content: "";
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        left: 40px;
        top: 50%;
      }

      &:hover {
        opacity: .8;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .sec.login dl {
    margin-top: 20px;
  }
}

@media screen and (max-width: 769px) {
  .sec.regist {
    margin: 40px auto 100px;
  }
}

@media screen and (max-width: 769px) {
  .sec .btnarea {
    margin-top: 20px;
  }
}


/*# sourceMappingURL=sourcemaps/login.css.map */
